import React, { useState } from 'react';

import { QLink } from './QLink';

export const QAppIcon = ({ app, platform, height, width }) => {
  const [imageError, setImageError] = useState(false);

  const handleImageError = () => {
    setImageError(true);
  };

  const iconUrl =
    app?.farm_app?.icon_large_url ||
    `data:image/png;base64,${app?.farm_app?.icon_base64}`;

  const appUrl = app?.farm_app?.store_url;

  const defaultIcon =
    platform === 'android' ? (
      <i data-testid="android-icon" className="fa-brands fa-android fa-4x" />
    ) : (
      <i data-testid="ios-icon" className="fa-brands fa-apple fa-4x" />
    );

  return (
    <QLink href={appUrl}>
      {iconUrl && !imageError ? (
        <img
          alt="App Icon"
          src={iconUrl}
          height={height}
          width={width}
          style={{ borderRadius: '13.75px' }}
          onError={handleImageError}
        />
      ) : (
        defaultIcon
      )}
    </QLink>
  );
};
