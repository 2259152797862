import React from 'react';
import ClearIcon from '@mui/icons-material/Clear';

import i18n from '../../localization/i18n';

import { QInput } from './QInput';
import { QInputIconWrapper } from './QInputIconWrapper';

export const QSearchBar = ({
  searchValue,
  onChange,
  onRemove,
  onKeyPress,
  placeholder = i18n.t('Search'),
}) => (
  <QInput
    type="text"
    id="app-query-input"
    onKeyPress={onKeyPress}
    onChange={onChange}
    value={searchValue}
    variant="outlined"
    placeholder={placeholder}
    InputProps={{
      startAdornment: (
        <QInputIconWrapper
          position="start"
          sx={{ padding: '12px 14px 12px 5px', margin: 0 }}
        >
          <i className="fa-regular fa-magnifying-glass" />
        </QInputIconWrapper>
      ),
      endAdornment: (
        <>
          {searchValue && (
            <QInputIconWrapper position="start">
              <ClearIcon onClick={onRemove} />
            </QInputIconWrapper>
          )}
        </>
      ),
    }}
  />
);
