import React, { Component } from 'react';
import { Row, Col, Badge, Tooltip } from 'reactstrap';
import bytes from 'bytes';

import harViewerUtil from '../../logic/harViewerUtil';

import EntryDetails from './EntryDetails';

const HttpMethod = ({ method }) => {
  let color = 'purple';
  if (method.toLowerCase() === 'get') {
    color = 'info';
  } else if (method.toLowerCase() === 'post') {
    color = 'navy';
  } else if (method.toLowerCase() === 'put') {
    color = 'pink';
  } else if (method.toLowerCase() === 'delete') {
    color = 'warning';
  }

  return <Badge color={color}>{method.toUpperCase()}</Badge>;
};

const TlsVersion = ({ tlsVersion }) => {
  if (!tlsVersion) {
    return <Badge color="warning">HTTP</Badge>;
  }
  if (tlsVersion === 'TLSv1.2' || tlsVersion === 'TLSv1.3') {
    return <Badge color="success">{tlsVersion}</Badge>;
  }
  if (tlsVersion === 'TLSv1.1') {
    return <Badge color="warning">{tlsVersion}</Badge>;
  }
  return <Badge color="danger">{tlsVersion}</Badge>;
};

class TrafficEntry extends Component {
  constructor(props) {
    super(props);

    this.state = {
      entry: props.entry,
      showDetails: false,
      showFullUrlTooltip: false,
    };

    this.toggleDetails = this.toggleDetails.bind(this);
    this.toggleFullUrlTooltip = this.toggleFullUrlTooltip.bind(this);
  }

  toggleDetails() {
    this.setState({ showDetails: !this.state.showDetails });
  }

  toggleFullUrlTooltip() {
    this.setState({ showFullUrlTooltip: !this.state.showFullUrlTooltip });
  }

  render() {
    const { entry, showDetails, showFullUrlTooltip } = this.state;
    const { stripedBackground, index } = this.props;

    // A -1px margin-top fixes the border from doubling up
    const path = harViewerUtil.getPath(entry.request.url);
    return (
      <div
        style={{
          marginTop: '-1px',
          borderTop: '1px solid #eaeaea',
          borderBottom: '1px solid #eaeaea',
          backgroundColor: stripedBackground ? '#eaeaea' : '',
        }}
      >
        <Row>
          <Col>
            <Row className="pointer" onClick={this.toggleDetails}>
              {/* Path and open/close icon */}
              <Col xs="3" style={{ fontWeight: '600' }}>
                <i
                  className={`fa-solid ${
                    showDetails ? 'fa-square-minus' : 'fa-square-plus'
                  }`}
                />{' '}
                <span id={`path-${index}`}>
                  {path.substring(0, 30) + (path.length > 30 ? '...' : '')}
                </span>
              </Col>

              {/* Full URL tooltip */}
              <Tooltip
                className="animated fadeIn"
                placement="right"
                isOpen={showFullUrlTooltip}
                toggle={this.toggleFullUrlTooltip}
                target={`path-${index}`}
                delay={{ show: 400, hide: 0 }}
              >
                {entry.request.url.split('?')[0]}
              </Tooltip>

              {/* Request method */}
              <Col xs="1" className="text-center">
                <HttpMethod method={entry.request.method} />
              </Col>

              {/* Server IP */}
              <Col xs="2" className="text-center">
                {entry.serverIPAddress}
              </Col>

              {/* Response status */}
              <Col xs="1" style={{ fontWeight: '500' }}>
                {`${entry.response.status} ${entry.response.statusText}`}
              </Col>

              {/* Response size */}
              <Col xs="1">{bytes(entry.response.bodySize)}</Col>

              {/* Request time */}
              <Col xs="1">{harViewerUtil.getRequestTime(entry.timings)}</Col>

              {/* TLS version if HTTPS request or just showing that the request is via HTTP */}
              <Col xs="1">
                <TlsVersion tlsVersion={entry.tls_version} />
              </Col>
            </Row>
            {showDetails && (
              <Row className="entry-details-row">
                <Col>
                  <EntryDetails entry={entry} index={index} />
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

export default TrafficEntry;
