import React from 'react';

import { QContainer } from '../Q-Components/QContainer';
import { QRow } from '../Q-Components/QRow';
import { QCol } from '../Q-Components/QCol';
import { QBadge } from '../Q-Components/QBadge';
import { QChip } from '../Q-Components/QChip';

const VersionDetails = ({ app }) => {
  if (app.farm_app.status !== 2 && app.farm_app.status !== 30) {
    return <i className="fa fa-spin fa-spinner" />;
  }
  if (app.farm_app.status === 30) {
    return (
      <QBadge
        variant="red"
        label={
          <>
            <i className="fa-solid fa-rectangle-xmark mr-1" />
            Cancelled
          </>
        }
        color="danger"
        pill
      >
        <i className="fa-solid fa-rectangle-xmark mr-1" />
        Cancelled
      </QBadge>
    );
  }
  let color;
  let variant;
  if (parseFloat(app.threat_score) > 75.0) {
    color = 'danger';
    variant = 'red';
  } else if (parseFloat(app.threat_score) > 35.0) {
    color = 'warning';
    variant = 'warning';
  } else {
    color = 'info';
    variant = 'blue';
  }

  return (
    <tc>
      {/* App data */}
      <QContainer fluid sx={{ backgroundColor: 'unset' }}>
        <QRow>
          <QCol xs="2" className="text-right" sx={{ flex: '2' }}>
            <QRow>
              {app.farm_app.icon_small_url ? (
                <a href={app.farm_app.store_url} target="_blank">
                  <img
                    src={app.farm_app.icon_small_url}
                    height="70px"
                    alt="App Icon"
                  />
                </a>
              ) : app.farm_app.icon_base64 ? (
                <img
                  alt="Icon"
                  src={`data:image/png;base64,${app.farm_app.icon_base64}`}
                  height="70px"
                />
              ) : app.farm_app.platform === 'android' ? (
                <i className="fa-brands fa-android fa-2x" />
              ) : (
                <i className="fa-brands fa-apple fa-2x" />
              )}
            </QRow>
          </QCol>

          <QCol xs="6" className="text-left" sx={{ flex: '6' }}>
            <h6>
              <QRow style={{ marginBottom: 5 }}>
                <strong>{app.farm_app.name}</strong>
              </QRow>
              <QRow style={{ marginBottom: 5 }}>
                <i>{app.farm_app.package}</i>
              </QRow>
              <QRow style={{ marginBottom: 5 }}>
                {app.farm_app.platform === 'android' ? (
                  <i className="fa-brands fa-android mt-1" />
                ) : (
                  <i className="fa-brands fa-apple mt-1" />
                )}
                &nbsp;
                {app.farm_app.version}
              </QRow>
              {app.external_id && (
                <QRow style={{ marginBottom: 5 }}>
                  <span>
                    <strong>EID:</strong> {app.external_id}
                  </span>
                </QRow>
              )}

              <QRow style={{ marginBottom: 5 }}>
                <QChip
                  color="primary"
                  style={{ color: 'white', marginRight: '10px' }}
                  variant="primaryDark"
                  label={app.submissionTag}
                >
                  {app.submissionTag}
                </QChip>
              </QRow>
              {app.signingAuth && app.signingAuth.toLowerCase() === 'none' && (
                <QRow style={{ marginTop: 5 }}>
                  <QBadge
                    color="warning"
                    variant="warning"
                    label={
                      <>
                        <i className="fa-light fa-exclamation-circle" /> Bad
                        cert
                      </>
                    }
                  >
                    <i className="fa-light fa-exclamation-circle" /> Bad cert
                  </QBadge>
                </QRow>
              )}
            </h6>
          </QCol>
          <QCol xs="4" className="text-left" sx={{ flex: '4' }}>
            <h5>
              <QBadge
                color={color}
                className="threat-score-badge"
                variant={variant}
                label={app.threat_score?.toString()}
              >
                {app.threat_score}
              </QBadge>
            </h5>
          </QCol>
        </QRow>
      </QContainer>
    </tc>
  );
};

export default VersionDetails;
