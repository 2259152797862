import React from 'react';
import { Container, Row, Col, Badge } from 'reactstrap';

// https://stackoverflow.com/a/40201629
export const byVersion = (a, b) =>
  a?.localeCompare(b, undefined, { numeric: true });

function SummarizedAppInfo({ app, innerRef, href }) {
  const version = app.versions.slice().sort(byVersion)[app.versions.length - 1];
  const appCountText = `${app.analyzed_apps.length} version${
    app.analyzed_apps.length > 1 ? 's' : ''
  } analyzed`;

  return (
    <Container fluid>
      <Row>
        <Col xs="1" className="text-right">
          <Row>
            {app.icon_small_url ? (
              <a href={app.store_url} target="_blank">
                <img src={app.icon_small_url} height="30px" alt="App Icon" />
              </a>
            ) : app.icon_base64 ? (
              <img
                alt="Icon"
                src={`data:image/png;base64,${app.icon_base64}`}
                height="30px"
              />
            ) : app.platform === 'android' ? (
              <i className="fa-brands fa-android fa-3x" />
            ) : (
              <i className="fa-brands fa-apple fa-3x" />
            )}
          </Row>
        </Col>

        <Col xs="10" className="text-left">
          <a href={href} ref={innerRef} className="no-link">
            <Row>
              <strong>{app.name}</strong>&nbsp;&nbsp;<i>{app.package}</i>
            </Row>
            <Row>
              {app.platform === 'android' ? (
                <i className="fa-brands fa-android mt-1" />
              ) : (
                <i className="fa-brands fa-apple mt-1" />
              )}
              &nbsp;
              {version} latest
            </Row>
            <Row>
              <span>{appCountText}</span>
            </Row>
            {/* eslint-disable-next-line */}
            {app?.analyzed_apps?.[0]?.external_id && (
              <Row>
                <span>
                  <strong>EID:</strong> {app.analyzed_apps[0].external_id}
                </span>
              </Row>
            )}
            {/* Check if the application is an iOS app and does not have a store 
            URL so we can show that the app is a binary submission. We mainly due this
            to satisfy a requirement from an evaluation to show that a fake iOS app
            was uploaded to the portal */}
            {!app.store_url ? (
              <Row>
                <Badge color="secondary" style={{ color: 'white' }}>
                  Binary app
                </Badge>
              </Row>
            ) : null}
          </a>
        </Col>
      </Row>
    </Container>
  );
}

export default React.forwardRef((props, ref) => (
  <SummarizedAppInfo {...props} innerRef={ref} />
));
